import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [error, setError] = useState('');
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [user, setUser] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setUser(user);  // Save the user object
        setIsMfaRequired(true);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error signing in', error);
    }
  };

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    try {
      await Auth.confirmSignIn(user, mfaCode, 'SOFTWARE_TOKEN_MFA');
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
      console.error('Error confirming sign in', error);
    }
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
      <h1>Welcome to sandbox refresh!</h1>
      <div className="card p-4 mt-4" style={{ width: '400px' }}>
        <h2 className="card-title text-center">Login</h2>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        {!isMfaRequired ? (
          <form onSubmit={handleLogin} className="mt-3">
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username:</label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">Login</button>
          </form>
        ) : (
          <form onSubmit={handleMfaSubmit} className="mt-3">
            <div className="mb-3">
              <label htmlFor="mfaCode" className="form-label">MFA Code:</label>
              <input
                type="text"
                className="form-control"
                id="mfaCode"
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">Submit MFA</button>
          </form>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
