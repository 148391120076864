import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './Components/login';
import Dashboard from './Components/Dashboard';
import { Amplify } from 'aws-amplify';
import PrivateRoute from './Components/Privateroute';
import config from './amplifyconfuguration.json';
Amplify.configure(config);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage/>} />
        {/* <Route path="/dashboard" element={<Dashboard/>} /> */}
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/" element={<LoginPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
