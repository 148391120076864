import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
// import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import config from '../amplifyconfuguration.json';
import DateTimePicker from 'react-datetime-picker';
// Import CSS files for DateTimePicker and its dependencies
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AWS from 'aws-sdk';
import '../App.css';
Amplify.configure(config);

function Dashboard() {
  const navigate = useNavigate();
  const lambda = new AWS.Lambda();
  const [jiraId, setJiraId] = useState('');
  const [email, setEmail] = useState('');
  const [clientNames, setClientNames] = useState([]);
  const [data, setData] = useState([]); // To store the fetched data
  const [selectedClientName, setSelectedClientName] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [productionNames, setProductionNames] = useState([]);
  const [selectedProductionName, setSelectedProductionName] = useState('');
  const [testingNames, setTestingNames] = useState([]);
  const [selectedTestingName, setSelectedTestingName] = useState('');

  // New state variables for scheduling
  const [refreshOption, setRefreshOption] = useState('startNow'); // 'startNow' or 'scheduleLater'
  const [scheduledTime, setScheduledTime] = useState(null);

  useEffect(() => {
    // Configure AWS SDK with Cognito Identity 
    Auth.currentCredentials().then(credentials => {
      const awsCredentials = Auth.essentialCredentials(credentials);
      Auth.currentSession().then(session => {
        AWS.config.region = 'ap-southeast-2';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId:  'ap-southeast-2:dab71613-f090-4601-b430-2dd3a76aec98', 
          Logins: {
            'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_G2MokH52O': session.getIdToken().getJwtToken()
          },
        });
     })
      fetchUserInfo();
    });
  }, []);

  const handleSubmit = async () => {
    console.log("in handle submit");
    const params = {
      FunctionName: 'assetic-submit-batch-job-sand', // Lambda function
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
        param1: selectedProductionName,
        param2: selectedTestingName,
        param3: jiraId,
        param4: email
      }),
    };

    try {
      AWS.config.credentials.get(async (error) => {
        if (error) {
          console.error('Error retrieving credentials:', error);
          return;
        }
        const data = await lambda.invoke(params).promise();
        alert("Sandbox Refresh Job is submitted and currently being processed....");
      });
    } catch (error) {
      console.error('Lambda invocation failed:', error);
    }
  };  

  // New handler for scheduling
  const handleSchedule = async () => {
    console.log("in handle schedule");
    const params = {
      FunctionName: 'assetic-submit-event-bridge-scheduler', // Lambda function
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
        param1: selectedProductionName,
        param2: selectedTestingName,
        param3: jiraId,
        param4: email,
        scheduledTime: scheduledTime.toISOString() // Ensure to send the scheduled time in a proper format
      }),
    };

    try {
      AWS.config.credentials.get(async (error) => {
        if (error) {
          console.error('Error retrieving credentials:', error);
          return;
        }
        const data = await lambda.invoke(params).promise();
        alert("Sandbox Refresh Job is scheduled and will be processed at the scheduled time.");
      });
    } catch (error) {
      console.error('Lambda invocation failed:', error);
    }
  };

  useEffect(() => {
    Auth.currentCredentials().then(credentials => {
      Auth.currentSession().then(session => {
        AWS.config.region = 'ap-southeast-2';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'ap-southeast-2:dab71613-f090-4601-b430-2dd3a76aec98',
          Logins: {
            'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_G2MokH52O': session.getIdToken().getJwtToken(),
          },
        });

        getClientandEnv();
      });
    });
  }, []);

  const getClientandEnv = async () => {
    const lambda = new AWS.Lambda();
    const params = {
        FunctionName: 'Provisioning_GetAllEnvironments_production',
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
            "key1": "",
            "key2": "",
            "key3": ""
        }),
    };

    try {
        const data = await lambda.invoke(params).promise();
        const responseData = JSON.parse(data.Payload);
        console.log('response data looks like this:', responseData);
        setData(responseData.data); // Store the entire response data for later processing

        let extractedClientNames = [...new Set(responseData.data.map(item => item.clientName))];
        
        // Sort the client names alphabetically
        extractedClientNames = extractedClientNames.sort((a, b) => a.localeCompare(b));
        
        setClientNames(extractedClientNames); // Update client names for the dropdown
    } catch (error) {
        console.error('Error invoking Lambda:', error);
    }
  };

  const handleClientNameChange = (clientName) => {
    setSelectedClientName(clientName);
    setProductionNames([]);
    setSelectedProductionName('');
    setTestingNames([]);
    setSelectedTestingName('');
    setJiraId('');
    
    const filteredData = data.filter(item => item.clientName === clientName && item.environmentType === "Production");
    const uniqueProductionNames = [...new Set(filteredData.map(item => item.name))];
    setProductionNames(uniqueProductionNames);
  };

  const handleProductionNameChange = (productionName) => {
    setSelectedProductionName(productionName);
    setTestingNames([]);
    setSelectedTestingName('');
    setJiraId('');
    const filteredData = data.filter(item => 
        item.clientName === selectedClientName && 
        item.environmentType === "Testing"
    );
    const uniqueTestingNames = [...new Set(filteredData.map(item => item.name))];
    setTestingNames(uniqueTestingNames);
  };

  const handleTestingNameChange = (name) => {
    setSelectedTestingName(name); // Update the selected testing name
  };

  const fetchUserInfo = async () => {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      const userEmail = currentAuthenticatedUser.attributes.email;
      setEmail(userEmail);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/login'); // Redirect to login page after successful logout
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  
return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: '#343a40'}}>
        <div className="container-fluid">
        <a className="navbar-brand" href="#" style={{ color: '#ffc107' }}>Dashboard</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
            </ul>
            <button className="btn btn-outline-danger ms-auto" style={{ color: '#ffc107' }} onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </nav>

    {/* Dashboard */}
  <div className="container mt-5">
    <h3>Select details below for Sandbox Refresh</h3>
    <div className="mb-3">
        <label htmlFor="client-name-select" className="form-label">Choose a client name:</label> 
        <select value={selectedClientName} className="form-select custom-dropdown-size" onChange={e => handleClientNameChange(e.target.value)}>
            <option value="">Select a Client</option>
            {clientNames.map(clientName => (
                <option key={clientName} value={clientName}>{clientName}</option>
            ))}
        </select>
    </div>    
    {/* EnvironmentType dropdown, shown if clientName is selected */}
    {productionNames.length > 0 && (
      <div className="mb-3">
        <label htmlFor="environment-select" className="form-label">Select Client's Production Environment:</label>
        <select value={selectedProductionName} className="form-select custom-dropdown-size" onChange={e => handleProductionNameChange(e.target.value)}>
        <option value="">Select a Production Name</option>
        {productionNames.map(name => (
          <option key={name} value={name}>{name}</option>
        ))}
      </select>
      </div>
    )}

    {/* Name dropdown, shown if environmentType is selected */}
    {testingNames.length > 0 && (
      <div className="mb-3">
      <label htmlFor="sandbox-environment-select" className="form-label">Select Sandbox Env:</label>
      <select value={selectedTestingName} className="form-select custom-dropdown-size" onChange={e => handleTestingNameChange(e.target.value)} >
        <option value="">Select a Testing Name</option>
        {testingNames.map(name => (
          <option key={name} value={name}>{name}</option>
        ))}
      </select>
      </div>
    )}
 {selectedTestingName && (
    <>
      <div className="mb-3">
        <label htmlFor="userEmail" className="form-label">User's Email Address:</label>
        <input type="email" className="form-control custom-dropdown-size" id="userEmail" value={email} disabled />
      </div>
      <div className="mb-3">
        <label htmlFor="jiraId" className="form-label">SalesForce ID:</label>
        <input type="text" className="form-control custom-dropdown-size" id="jiraId" value={jiraId} onChange={(e) => setJiraId(e.target.value)} />
      </div>

      {/* New radio buttons for refresh option */}
      <div className="mb-3">
        <label className="form-label">Refresh Option:</label>
        <div>
          <label>
            <input 
              type="radio"
              value="startNow"
              checked={refreshOption === 'startNow'}
              onChange={(e) => setRefreshOption(e.target.value)}
            />
            Start Now
          </label>
          <label style={{ marginLeft: '20px' }}>
            <input
              type="radio"
              value="scheduleLater"
              checked={refreshOption === 'scheduleLater'}
              onChange={(e) => setRefreshOption(e.target.value)}
            />
            Schedule for Later (Melbourne Local Time)
          </label>
        </div>
      </div>

      {/* DateTimePicker shown when 'Schedule for Later' is selected */}
        {refreshOption === 'scheduleLater' && (
          <div className="mb-3">
          <label className="form-label">Select Scheduled Time:</label>
          <div className="input-group" style={{ maxWidth: '400px' }}>
            <DateTimePicker
              onChange={setScheduledTime}
              value={scheduledTime}
              minDate={new Date()}
              disableClock={true}
              className="form-control"
            />
          </div>
        </div>
        )}

      {/* Buttons */}
      <div>
        {refreshOption === 'startNow' && (
          <button onClick={handleSubmit} className="btn btn-primary w-20" disabled={!jiraId.trim()}>Start Sandbox Refresh</button>
        )}

        {refreshOption === 'scheduleLater' && (
          <button onClick={handleSchedule} className="btn btn-primary w-20" disabled={!jiraId.trim() || !scheduledTime}>
            Schedule Sandbox Refresh
          </button>
        )}
      </div>
    </>
  )}
  
  </div>
</div>


);
}

export default Dashboard;
